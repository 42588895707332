import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, {
      class: "mb-4",
      fontSize: 16,
      label: "Informasi klien"
    }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        class: "w-10/12",
        label: "Tipe klien",
        value: _ctx.detailData.clientType
      }, null, 8, ["value"]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Klien ID",
          value: _ctx.idClient
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Kode klien",
          value: _ctx.detailData.clientCode
        }, null, 8, ["value"])
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Nama induk klien",
          value: _ctx.detailData.clientName
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Kategori klien",
          value: _ctx.detailData.clientCategory
        }, null, 8, ["value"])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Ukuran perusahaan",
          value: _ctx.detailData.clientCompanySize
        }, null, 8, ["value"])
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Alamat",
          value: _ctx.detailData.clientAddress
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Kecamatan, Kota",
          value: _ctx.detailData.clientDistrict?.name
        }, null, 8, ["value"])
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Email",
          value: _ctx.detailData.clientEmail
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "No. Telepon",
          value: _ctx.formatPhoneNumber(_ctx.detailData.clientPhone)
        }, null, 8, ["value"])
      ])
    ])
  ], 64))
}